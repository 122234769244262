.infobox {
  background-color: white;
  min-width: 300px;
  width: 100%;
  max-width: 500px;
  position: absolute;
  bottom: 0;
  left: 0;
  resize: horizontal;
  overflow: auto;
  z-index: 1000;
}
.infobox__header {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  min-height: 56px;
}
.closeIcon {
  height: 24px;
}
.infobox__content {
  max-height: calc(
    100vh - 300px
  ); /* Fallback for browsers that do not support Custom Properties */
  max-height: calc(var(--vh, 1vh) * 100 - 300px);
  overflow-y: auto;
}
.infobox__layertitle {
  max-width: calc(100vw - 100px);
}
.attributes__table {
  border-collapse: collapse;
  font-size: 12px;
  width: 100%;
  word-break: break-all;
}
.attributes__table,
.attributes__tablerow,
.attributes__tablecell {
  border: 1px dotted black;
}
.attributes__tablecell-grey {
  background-color: gainsboro;
}
.attributes__tablecell {
  padding: 4px;
}
