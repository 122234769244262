.react-autosuggest__container {
  display: flex;
  height: 36px;
  position: relative;
}

.react-autosuggest__input {
  -webkit-appearance: none;
  border: none;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  font-weight: 300;
  height: 36px; /* necessary for safari */
  padding: 8px;
  width: 100%;
}

[placeholder] {
  text-overflow: ellipsis;
}

.react-autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #ffda06;
  box-shadow: 0 0 4px #ffda06;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #808080;
  font-family: "Roboto", sans-serif;
  opacity: 1; /* Firefox */
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #808080;
  font-family: "Roboto", sans-serif;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  color: black;
  display: none;
}

.react-autosuggest__suggestions-container--open {
  background-color: #fff;
  border: 1px solid #aaa;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  display: block;
  max-height: calc(
    100vh - 202px
  ); /* Fallback for browsers that do not support Custom Properties */
  max-height: calc(var(--vh, 1vh) * 100 - 202px);
  overflow-y: auto;
  position: absolute;
  top: 56px;
  width: 100%;
  z-index: 1001;
}

.react-autosuggest__suggestions-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 5px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #5897fb;
}

.react-autosuggest__section-title {
  background-color: whitesmoke;
  border-bottom: 1px dashed gray;
  color: #777;
  font-size: 18px;
  padding: 20px 0 10px 10px;
}

.ais-Highlight-highlighted {
  background-color: #ffda06;
}

@media screen and (max-width: 992px) {
  .react-autosuggest__suggestions-container--open {
    min-width: 0;
  }
}

@media screen and (max-width: 480px) {
  .ais-InstantSearch__root {
    flex-grow: 1;
  }
  .react-autosuggest__suggestions-container--open {
    width: 100%;
  }
}
