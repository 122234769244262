.appHeader__companyname {
  padding-left: 12px;
  display: none;
}

.appHeader__search {
  position: relative;
  background-color: "#fff";
  margin-right: 16px;
  margin-left: 0;
  width: 80%;
}

.appHeader__spacer {
  flex-grow: 1;
}

.appHeader__desktop {
  display: none;
}

.appHeader__text {
  font-size: 0.875rem;
  padding: 0 0 0 4px;
}

@media screen and (min-width: 600px) {
  .appHeader__companyname {
    display: block;
  }
  .appHeader__search {
    width: 50%;
  }
}

@media screen and (min-width: 900px) {
  .appHeader__desktop {
    display: flex;
  }
  .appHeader__mobile {
    display: none;
  }
}
