.ol-tooltip {
  position: relative;
  background: rgba(0, 0, 0, 0.9);
  border-radius: 4px;
  color: white;
  padding: 4px 8px;
  opacity: 0.7;
  white-space: nowrap;
  font-size: 14px;
  line-height: 1.5em;
}
.ol-tooltip-measure {
  opacity: 1;
  font-weight: bold;
}
.ol-tooltip-static {
  background-color: #ffcc33;
  color: black;
  border: 1px solid white;
}
.ol-tooltip-measure:before,
.ol-tooltip-static:before {
  border-top: 6px solid rgba(0, 0, 0, 0.5);
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  content: "";
  position: absolute;
  bottom: -6px;
  margin-left: -7px;
  left: 50%;
}
.ol-tooltip-static:before {
  border-top-color: #ffcc33;
}
.buttonGroup__container {
  display: flex;
  justify-content: space-between;
}
.tools__color {
  border: 1px solid lightgray;
  border-radius: 15px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
}
.tools__checkCircle {
  color: gold;
}
.input__fontsize {
  height: 34px;
  max-width: 95%;
}
.textinput__container {
  background-color: rgba(0, 0, 0, 0.66);
  border-radius: 8px;
  padding: 12px;
}
.textinput__buttoncontainer {
  display: flex;
  justify-content: space-between;
  padding: 12px 0 0 0;
}
