.main {
  height: calc(
    100vh - 66px
  ); /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100 - 66px);
}

#ol-map,
#cesium-map {
  height: calc(
    100vh - 66px
  ); /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100 - 66px);
  overflow: hidden;
  position: absolute;
  top: 66px;
  left: 0;
  width: 100%;
}

.ol-popup {
  position: absolute;
  background-color: white;
  -webkit-filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
  filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #cccccc;
  bottom: 12px;
  left: -50px;
  min-width: 200px;
}
.ol-popup:after,
.ol-popup:before {
  top: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.ol-popup:after {
  border-top-color: white;
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
}
.ol-popup:before {
  border-top-color: #cccccc;
  border-width: 11px;
  left: 48px;
  margin-left: -11px;
}
.ol-popup-closer {
  color: black;
  cursor: pointer;
  text-decoration: none;
  position: absolute;
  top: 2px;
  right: 8px;
}
.ol-popup-closer:after {
  content: "✖";
}

/*mui expansion panels global style change*/
.MuiAccordionSummary-root.Mui-expanded {
  min-height: 0 !important;
}
.MuiAccordionSummary-content.Mui-expanded {
  margin: 0 !important;
}

/*
* position button animation
*/
.animatePositionButton {
  animation: pulse 1.5s alternate infinite;
  border: 3px solid red;
}
@keyframes pulse {
  0% {
    transform: scale(0.75);
  }
  100% {
    transform: scale(1);
  }
}
