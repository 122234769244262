.basemaps {
  padding: 16px 16px 0 16px;
}

.basemaps__objects {
  display: flex;
  flex-wrap: wrap;
  font-size: 0.7em;
}

.basemaps__object {
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 62px;
  padding-bottom: 8px;
}

.basemaps__image {
  border: 3px solid white;
  width: 56px;
  height: 56px;
  border-radius: 8px;
}

.basemap__image_active {
  border: 2px solid #fdcc4b;
}

.basemaps__zeitreisecontainer {
  padding: 4px 16px 16px 16px;
}
