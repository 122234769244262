.layer__top,
.layer__bottom {
  align-items: center;
  display: flex;
}
.layer__top {
  background-color: whitesmoke;
}
.layer__bottom {
  flex-wrap: wrap;
}
.layer__bottom-timetitle {
  align-items: center;
  display: flex;
  font-size: 14px;
}
.layer__bottom-historyicon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}
.layer__handle {
  cursor: move;
  cursor: -webkit-grabbing;
}
.layer__bottom-slider {
  padding: 4px 4px 0 4px;
  width: 65%;
}
.layer__bottom-timeslider {
  padding: 4px 4px 0 12px;
  width: 95%;
}
.spacer {
  flex-grow: 1;
}
.extwms {
  color: red;
  font-size: 10px;
  margin: 0 0 0 4px;
}

/*
* drag n' drop 
*/
.ghost {
  border: 1px solid red;
}
