.geodatenkatalog__list {
  overflow-y: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.geodatenkatalog__nolayers {
  padding: 12px;
  text-align: center;
}
.layerfilter {
  margin-bottom: 16px;
  width: 100%;
}
.layercard {
  width: 100%;
  max-width: 100%;
}
.layeractions {
  display: flex;
  width: 100%;
}
.layeractions__item {
  text-align: center;
  width: 25%;
}
