.sidebar {
  background-color: white;
  padding: 8px;
  position: absolute;
  right: 0;
  transform: scale(1, 1);
  transform-origin: top left;
  transition: transform 0.3s, z-index 0.3s;
  top: 102px;
  width: 360px;
  max-width: 100%;
  box-shadow: 4px 0px 3px 0px rgba(0, 0, 0, 0.75);
}

.sidebar__content {
  padding: 12px 0 0 0;
  max-height: calc(100vh - 155px);
  max-height: calc(var(--vh, 1vh) * 100 - 155px);
  overflow: hidden;
  overflow-y: auto;
}

.diagramsidebar {
  background-color: white;
  position: absolute;
  bottom: 0;
  transform: scale(1, 1);
  transform-origin: bottom left;
  transition: transform 0.3s;
  width: 100%;
  box-shadow: 4px 0px 3px 0px rgba(0, 0, 0, 0.75);
  z-index: 100;
}

.diagramsidebar__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px;
}

.sidebar--collapsed {
  transform: scale(1, 0);
}

.diagramsidebar__header {
  display: flex;
  justify-content: space-between;
}

.diagramsidebar__statisticsection {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.diagramsidebar__statistic {
  align-items: center;
  cursor: default;
  display: flex;
  font-size: 0.8em;
  padding-right: 12px;
}

.diagramsidebar__statistic--image {
  width: 24px;
  height: 24px;
}

.diagramsidebar__statistic--geodata {
  font-size: 0.7em;
  font-weight: bold;
}
