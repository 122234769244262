.basemapContainer {
  border-radius: 25px;
  box-shadow: 0 2px 4px 0 #4a4a4a;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0.5em;
  right: 0.5em;
  position: absolute;
}

.layerIcon {
  width: 36px;
  height: 36px;
}

.basemapContainer:hover {
  background-color: none;
}
.ol-attribution ul {
  font-size: 0.7em;
}
.ol-attribution a {
  color: #0d6efd;
  text-decoration: underline;
}
.ol-zoom {
  bottom: 2.2em !important;
  right: 0.5em !important;
  top: unset !important;
  left: unset !important;
}

.ol-zoom-in,
.ol-zoom-out,
.ol-rotate-reset {
  cursor: pointer !important;
}

.ol-rotate {
  bottom: 7em !important;
  top: unset !important;
}

@media screen and (max-width: 400px) {
  .basemapContainer {
    top: 2.5em;
  }
}
