.iconmenu {
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  height: 36px;
  left: 0;
  overflow: auto;
  padding: 6px 0;
  position: absolute;
  top: 66px;
  left: 0;
  width: 100%;
  max-width: 100%;
  z-index: 100;
}

.menuchip__colorsecondary {
  background-color: #fbc02d !important;
  color: black !important;
}
